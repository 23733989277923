import React from 'react';
import Fade from 'react-reveal/Fade';

function AboutUs() {
  return (
    <div>
      <section id='aboutUs' className="bg-container p-8 lg:py-16">
        <div className="max-w-6xl mx-auto text-center lg:text-left">
          <div className="heading mb-8">
            <h1 className="text-4xl lg:text-5xl font-bold text-center">
              <strong>About Us</strong>
            </h1>
          </div>
          <div className="text-content text-center">
            <Fade bottom>
              <p className="text-lg lg:text-xl text-gray-600 mb-6">
                At SunMicroTec, we proudly hold the distinction of being pioneers in Computer Education, Software Development, and Hardware & Software Sales and Service in the Kodagu district. Established in 1995, we have been at the forefront of technological innovation, serving our community with dedication and expertise. With over two decades of expertise, we've earned the trust of our community, becoming a cornerstone in the region's tech landscape.
              </p>
            </Fade>
          </div>
          <div className="text-content lg:flex lg:items-center">
            <Fade left>
              <div className="lg:w-1/2 pr-4">
                <div className="text-lg lg:text-xl text-gray-600">
                  <h2 className="text-2xl font-semibold mb-4">
                    <strong>Our Vision</strong>
                  </h2>
                  <p>
                    Our journey began with a vision to bridge the digital divide and empower individuals and businesses with the latest in technology. Over the years, we have grown and evolved, but our commitment to this vision remains unwavering.
                  </p>
                </div>
              </div>
            </Fade>
            <Fade right>
              <div className="lg:w-1/2 pl-4">
                <div className="text-lg lg:text-xl text-gray-600">
                  <h2 className="text-2xl font-semibold mb-4">
                    <strong>Our Commitment</strong>
                  </h2>
                  <p>
                    <strong className="text-slate-500">Customer Satisfaction at the Core:</strong> At SunMicroTec, customer satisfaction isn't just a goal; it's our guiding principle. Our dedicated team works tirelessly to meet your needs, ensuring seamless experiences with our services. Your trust fuels our commitment to deliver excellence.
                  </p>
                  <p>
                    <strong className="text-slate-500">Innovation in Every Service:</strong> From cutting-edge Software Development for schools and institutions to reliable Hardware Sales and Services, our offerings are marked by innovation. We specialize in crafting tailored solutions for businesses of all sizes, emphasizing efficiency and cost-effectiveness.
                  </p>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AboutUs;
