import React from 'react';

function Page2() {
  return (
    <div id="page2" className="hero min-h-screen" style={{backgroundImage: 'url(sunvidyabg.png)'}}>
      <div className="hero-overlay bg-opacity-20"></div>
      <div className="hero-content text-center text-black mt-20">
        <div className="max-w-xxl p-5 bg-white bg-opacity-80 rounded-lg shadow-xl">
          <h2 className="mb-5 text-2xl font-bold">
            SunVidya revolutionizes education with tech-driven solutions. From interactive classrooms, RFID cards, and GPS tracking to our ERP software for the complete management of the institutions, we empower schools and colleges for a brighter future.
          </h2>
          <button className="btn " onClick={() => { window.location.href = '/sunvidya'; }}>Visit Sunvidya</button>
        </div>
      </div>
    </div>
    
  );
}

export default Page2;
