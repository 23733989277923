import React from 'react'
import SNavbar from '../components/snavbar'

function sunvidya() {
  return (
    <div>
        <SNavbar/>
    </div>
  )
}

export default sunvidya