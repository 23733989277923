import React from 'react'
import Navbar from '../components/navbar';
import Page3 from '../components/page3';
import Footer from '../components/footer';
import ContactUs from '../components/ContactUs';
import Carousel from '../components/Carousel';
import AboutUs from '../components/aboutus';

function sunmicrotec() {
    return (
        <div>
            <Navbar />
            <Carousel />
            <Page3 />
            <ContactUs />
            <AboutUs />
            <Footer />
        </div>
    )
}

export default sunmicrotec