import React from 'react';

function Footer() {
  return (
    <footer id='footer' className="flex flex-col items-center text-center lg:text-left">
      <div className="container p-6 text-black">
        <div className="lg:flex lg:justify-between">
          <div className="mb-6 md:mb-0 lg:w-1/2">
            <h5 className="mb-1 font-medium uppercase">Address</h5>
            <p className="mb-4">
              Ratha Beedi<br />
              Kushalnagar, Karnataka 571234<br />
              India
            </p>

            <h5 className="mb-1 font-medium uppercase">Opening Hours</h5>
            <table className="table text-black border-gray-700 lg:w-1/2">
              <tbody>
                <tr>
                  <td>Mon - Fri:</td>
                  <td>9am - 7pm</td>
                </tr>
                <tr>
                  <td>Sat:</td>
                  <td>9am - 6pm</td>
                </tr>
                <tr>
                  <td>Sun:</td>
                  <td>Closed</td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Google Map */}
          <div className="mb-6 md:mb-0 lg:w-1/2">
            <div className="map-container">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3895.8670396281555!2d75.9599448746799!3d12.458574325919196!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba509f16e75e7f9%3A0xcc151074de3c0a48!2sSun%20Micro%20Tec%20It%20Solutions!5e0!3m2!1sen!2sin!4v1694092695571!5m2!1sen!2sin"
                title="Google Map"
                width="100%" // Adjust the width as needed
                height="200" // Adjust the height as needed
                style={{ border: '0' }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
              <a
                href="https://www.google.com/maps/dir//Sun+Micro+Tec+It+Solutions/data=!4m8!4m7!1m0!1m5!1m1!1s0x3ba509f16e75e7f9:0xcc151074de3c0a48!2m2!1d75.9625198!2d12.4585691"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-md btn-dark contact-button mt-7"
              >
                Get Directions
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Copyright section */}
      <div className="w-full bg-neutral-200 p-4 text-center text-neutral-700 dark:bg-neutral-700 dark:text-neutral-200">
        © 2024 Copyright: 
        <a
          href="https://www.sunmicrotec.com" // Replace with your website URL
          target="_blank"
          rel="noopener noreferrer"
          className="text-neutral-800 dark:text-neutral-400"
        >
          SunMicrotec
        </a>
      </div>
    </footer>
  );
}

export default Footer;
