import { Link } from 'react-scroll';
import Fade from 'react-reveal/Fade';

function Page3() {
  return (
    <div>
      <section id='page3' className="bg-container p-8 lg:py-16">
        <div className="max-w-6xl mx-auto text-center lg:text-left">
          <div className="heading mb-8">
            <h1 className="text-4xl lg:text-5xl font-bold">
              <strong>Introducing Online Shopping and Service</strong>
            </h1>
          </div>
          <div className="flex flex-col lg:flex-row items-center lg:items-stretch">
            <Fade left>
              <div className="text-content lg:w-1/2 lg:pr-8">
                <p className="text-lg lg:text-xl mb-6 lg:mb-0">
                  Discover laptops, peripherals, refurbished products, rentals,
                  repairs, and more – all at your fingertips. Explore our
                  legacy, shop securely, and experience top-notch service from
                  anywhere.
                </p>
              </div>
            </Fade>
            <div className="lg:divider-horizontal mx-4 lg:mx-8"></div>
            <Fade right>
              <div className="grid flex-grow h-32 card rounded-box place-items-center lg:w-1/2">
                {/* Replace the image source with your actual image path */}
                <img
                  src="logo1.png"
                  alt="Logos"
                  className="max-h-full h-auto"
                  style={{maxWidth: '100%' }}
                />
              </div>
            </Fade>
          </div>
        </div>
      </section>
      <br />
      <br />
      <br />
      <br />

      <section className="bg-container p-8 lg:py-16">
        <div className="max-w-6xl mx-auto text-center lg:text-left">
          <div className="heading mb-8">
            <h1 className="text-4xl lg:text-5xl font-bold">
              <strong>Expert CCTV Installations and Surveillance Services</strong>
            </h1>
          </div>
          <div className="flex flex-col lg:flex-row items-center lg:items-stretch">
            <Fade left>
              <div className="text-content lg:w-1/2 lg:pr-8">
                <p className="text-lg lg:text-xl mb-6 lg:mb-0">
                  Elevate security with SunMicroTec's CCTV expertise. Our team
                  designs and deploys tailored surveillance systems, ensuring
                  constant vigilance against potential threats. Trust us for
                  efficient, reliable security solutions.
                </p>
                <div
                  className="btn btn-md sm:btn-md lg:btn-2xl contact-button mt-4 mb-7">
                <Link to="page4" spy={true} smooth={true}>Get a Call</Link>
                </div>
              </div>
            </Fade>
            <div className="lg:divider-horizontal mx-4 lg:mx-8"></div>
            <Fade right>
              <div className="grid flex-grow h-32 card rounded-box place-items-center lg:w-1/2">
                {/* Replace the image source with your actual image path */}
                <img
                  src="logo2.png"
                  alt="Logos1"
                  className="max-h-full h-auto"
                  style={{maxWidth: '100%' }}
                />
              </div>
            </Fade>
          </div>
        </div>
      </section>


      
      
    </div>
  );
}

export default Page3;
