import React from 'react'
import Hero from './hero'
import Page2 from './page2'

function Carousel() {
    return (
        <div className="carousel w-full">
            <div id="slide1" className="carousel-item relative w-full">
                <Hero />
                <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                    <a href="#slide2" className="btn btn-circle bg-transparent border-transparent">❮</a>
                    <a href="#slide2" className="btn btn-circle bg-transparent border-transparent">❯</a>
                </div>
            </div>
            <div id="slide2" className="carousel-item relative w-full">
                <Page2 />
                <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                    <a href="#slide1" className="btn btn-circle bg-transparent border-transparent">❮</a>
                    <a href="#slide1" className="btn btn-circle bg-transparent border-transparent">❯</a>
                </div>
            </div>
        </div>)
}

export default Carousel