import React, { useState } from "react";
import emailjs from "@emailjs/browser";
const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(e.target);
    setLoading(true);
    try {
      await emailjs.sendForm(
        "service_b8f9ifu",
        "template_uk9gerq",
        e.target,
        "yM4Urz8dWbnzFIBlH"
      );
      alert("Sent Successfully 😁😁 !")
    } catch (error) {
        // console.log(error);
        alert("Failed 🥲")
    }
    //form reset
    e.target.reset();
    setName("");
    setEmail("");
    setMessage("");
    setLoading(false);
  };

  return (
    <>
    <br />
      <br />
      <br />
    <div id='page4'></div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="flex sm:w-11/12 lg:w-6/12 mb-20  max-w-sm mx-auto overflow-hidden  rounded-lg shadow-lg backdrop-blur-md border-t-2 border-b-2  lg:max-w-4xl ">
        {/* <div className='sm:hidden lg:block  w-1/2' >
                    <img src="/images/contact.jpg" alt="" />
                </div> */}

        <div className="w-full px-6 py-8 md:px-8 ">
          <h2 className="text-2xl font-semibold text-center text-slate-950">
            Contact Us
          </h2>

          <p className="text-xl text-center text-slate-950">
            We are here to help you !!!
          </p>
          <form onSubmit={handleSubmit} method="POST" action="#">
            <div className="mt-4">
              <div className="flex justify-between">
                <label
                  className="block mb-2 text-sm font-medium text-slate-950"
                  htmlFor="LoggingEmailAddress"
                >
                  Email Address or Phone Number
                </label>
              </div>
              <input
                id="LoggingEmailAddress"
                className="block w-full px-4 py-2 text-slate-950 bg-white border rounded-lg focus:border-blue-400 focus:ring-opacity-40 focus:outline-none focus:ring focus:ring-blue-300"
                type="text"
                name="user_email"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="mt-4">
              <div className="flex justify-between">
                <label
                  className="block mb-2 text-sm font-medium text-slate-950"
                  htmlFor="LoggingEmailAddress"
                >
                  Name
                </label>
              </div>
              <input
                id="LoggingEmailAddress"
                className="block w-full px-4 py-2 text-slate-950 bg-white border rounded-lg focus:border-blue-400 focus:ring-opacity-40 focus:outline-none focus:ring focus:ring-blue-300"
                type="text"
                name="user_name"
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>

            <div className="mt-4">
              <div className="flex justify-between">
                <label
                  className="block mb-2 text-sm font-medium text-slate-950"
                  htmlFor="loggingPassword"
                >
                  Message
                </label>
              </div>

              <input
                id="loggingPassword"
                className="block w-full px-4 py-2 text-slate-950 bg-white border rounded-lg focus:border-blue-400 focus:ring-opacity-40 focus:outline-none focus:ring focus:ring-blue-300"
                type="text"
                name="user_message"
                onChange={(e) => setMessage(e.target.value)}
                required
              />
            </div>

            <div className="mt-6">
              <button className=" w-full btn btn-md sm:btn-xl lg:btn-2xl contact-button">
                {!loading ? "Send" : "Sending..."}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactUs;