import React from 'react';
import '../App.css'; // Assuming App.css is in the src folder
import { Link } from 'react-scroll';



function Hero() {
  return (
    <div id="hero" className="hero">
      <div className="hero-content text-center">
        <div className="max-w-xxl mx-auto text-left">
          <div className="text-white">
            <p
              className="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl mb-10 text-left !leading-[5vw]"
              style={{ fontFamily: "'EB Garamond'" }}
            >
              Your Trusted Partner for <br /> Technology Solutions and <br /> Services
            </p>
            <div className="btn sm:btn-xl lg:btn-2xl xl:btn-5xl 2xl:btn-6xl contact-button">
            <Link to="page4" spy={true} smooth={true}>Contact Us</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
